<template>
  <RevDialog
    :close-button-label="i18n(dsTranslations.close)"
    :name
    :title="i18n(translations.title)"
  >
    <template #body>
      <div class="body-1 my-7 text-center">
        {{ i18n(translations.body) }}
      </div>
    </template>

    <template #footer="{ close }">
      <div>
        <RevButton
          class="mb-16"
          full-width="always"
          variant="primary"
          @click="onClose"
        >
          {{ confirmButtonLabel }}
        </RevButton>
        <RevButton full-width="always" variant="secondary" @click="close">
          {{ i18n(translations.buttonCancel) }}
        </RevButton>
      </div>
    </template>
  </RevDialog>
</template>

<script setup lang="ts">
import { useI18n } from '@backmarket/nuxt-module-i18n/useI18n'
import { RevButton } from '@ds/components/Button'
import { RevDialog } from '@ds/components/Dialog'
import { closeModal } from '@ds/components/ModalBase'
import { dsTranslations } from '@ds/translations'

import translations from './RibConfirmationModal.translations'

const i18n = useI18n()

interface RibConfirmationModalProps {
  name: string
  confirmButtonLabel: string
}

const props = defineProps<RibConfirmationModalProps>()

const emit = defineEmits<{
  (event: 'confirmed'): void
}>()

function onClose() {
  emit('confirmed')
  closeModal(props.name)
}
</script>
